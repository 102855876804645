let menu_trigger = $(document).find('.menu-trigger');
let level_trigger = $(document).find('.level-trigger');

menu_trigger.on('click', function() {
  let $this = $(this);
  let $menu = $(document).find('.main-menu');

  $this.toggleClass('open');
  $menu.toggleClass('d-none');
  $('body').toggleClass('menu-open');
  
});

level_trigger.on('click', function() {
  let $this = $(this);
  let $parent = $this.closest('ul');
  let $sub_menus = $parent.find('.sub-menu');
  let $sub_menu = $this.siblings('.sub-menu');
  let $triggers = $parent.find('.level-trigger');

  if($sub_menu.is(":visible")) {
    $sub_menu.slideUp('fast');
    $this.removeClass('active');
  } else {
    $sub_menus.slideUp('fast');
    $sub_menu.slideDown('fast');
    $triggers.removeClass('active');
    $this.addClass('active');
  }
});