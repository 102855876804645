var $search = $(document).find('.exhibitor-search');
var $search_input = $search.find('.exhibitor-searchbox');

// search exhibitors
/*
if($search_input.length > 0) {
  var $exhibitors = $search.find('li');
  var $exhibitors_container = $search.find('ul');

  $search_input.on('input', function() {
    var $this = $(this);
    var value = $this.val();
    var no_results = true;

    if(value) {
      $exhibitors.each(function( i, exhibitor ) {
        if (!$(exhibitor).data('exhibitor').toLowerCase().includes(value.toLowerCase()) || value === "") {
          $(exhibitor).addClass('d-none');
        } else {
          $(exhibitor).removeClass('d-none');
          no_results = false;  
        }
      });
    }

    if(no_results) 
      $exhibitors_container.addClass('d-none');
    else
      $exhibitors_container.removeClass('d-none');
  })
}*/
if($search_input.length > 0) {
  $search_input.on('change', function() {
    window.location = $(this).val();
  });
}

// map click handler
var $map_paths = $(document).find('.svg-map svg path');

$map_paths.on('click', function(){
  let id = $(this).attr('id');

  if(id) {
    if ($(this).closest('.wkb-fair-map').length > 0) {
      var $map_data = $(this).closest('.wkb-fair-map').find('.hall-data').data('halls');
      window.location.href = $map_data[id];
    } else {
      $('[data-space="' + id + '"] input').prop("checked", true);
      selectExhibitorOnMap(id);
    }
  }
});

// exhibitr click handler
$('.exhibitor-list input[type="radio"]').on('change', function(){
  selectExhibitorOnMap($(this).val(), false);
});

// fair signle page map
var $exhibitor_list = $(document).find('.exhibitor-list');
if($exhibitor_list.length > 0)
  selectExhibitorOnMap();


// hall click handler
var $map_paths = $(document).find('.svg-map-hall svg path');
var $hall_select = $(document).find('#hall-select');
$map_paths.on('click', function(){
  let id = $(this).attr('id');
  if(id) {
    $hall_select.val(id);
    $hall_select.trigger('change');
  }
});



function selectExhibitorOnMap(id = null, scroll = true) {
  var $exhibitor_list = $(document).find('.exhibitor-list');
  var $map_data = $(document).find('.hall-data').data('halls');

  if($exhibitor_list.length > 0) {
    var svg_map = $(document).find('.svg-map');
    var selected_exhibitor = $exhibitor_list.find('input[name="space"]:checked');

    if(selected_exhibitor) {
      svg_map.find('.active').removeClass('active');

      if(id)
        window.history.pushState('', '', $map_data[id]);

      selected_exhibitor.each(function( i, exhibitor ) {
        var space = $(exhibitor).val();
        svg_map.find('#' + space).addClass('active');
      });
    }
  }

  // scroll to exhibitor
  if(scroll && selected_exhibitor.parent('li').length > 0) {
    $exhibitor_list.scrollTop(0);
    let parent_top = $exhibitor_list.position().top;
    let target_top = selected_exhibitor.parent('li').position().top;
    $exhibitor_list.scrollTop(target_top - parent_top);
  }
}