var mapbox = $(document).find('.wkb-mapbox');

if(mapbox.length > 0) {
  var buttons = mapbox.find('button');
  var marker_pin = mapbox.data('marker');
  var marker_bus_pin = mapbox.data('marker-bus');
  var marker_car_pin = mapbox.data('marker-car');
  var marker_train_pin = mapbox.data('marker-train');
  var marker_bike_pin = mapbox.data('marker-bike');
  var accessToken = mapbox.data('accesstoken');
  var fairLocation = mapbox.data('location');
  var parkings = mapbox.data('parkings');
  var bike_locations = mapbox.data('bokelocations');
  var mainBus = mapbox.data('main-bus');
  var mainTrain = mapbox.data('main-train');
  var stationsBus = mapbox.data('stations-bus');
  var gmapText = mapbox.data('gmap');
  var map = null;
  var allMarkers = [];
  var trainRoute = null;
  var mapInit = false;

  // init mapbox if user has element in view
  function callbackFunc(entries, observer) {
    entries.forEach(entry => {
      let $e = $(entry.target);
      if(entry.isIntersecting && !mapInit) {
        init_mapBox();
        mapInit = true;
      }
    });
  }
  
  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };

  let observer = new IntersectionObserver(callbackFunc, options);
  mapbox.each(function( e ) {
    observer.observe($(this)[0]);
  });


  function init_mapBox() {
    if(typeof mapboxgl !== undefined) {
      mapboxgl.accessToken = accessToken;
  
      addGmapLinks();
      
      map = new mapboxgl.Map({
        container: 'mapbox-map',
        zoom: '15',
        center: [fairLocation['lon'], fairLocation['lat']],
        style: 'mapbox://styles/mapbox/streets-v9',
        cooperativeGestures: true
      });
    
      addMarkers([fairLocation], marker_pin, true);
  
      init_carMap(parkings);
  
      buttons.on('click', function() {
        let $this = $(this);
        removeMarkers();

        switch ($this.data('type')) {
          case 'car':
            init_carMap();
            break;
          case 'bike':
            init_bikeMap();
            break;
          case 'train':
            init_trainMap();
            break;
          case 'bus':
            init_busMap();
            break;
        }
  
        fitBounds();
       
      });
    }
  }

  function init_carMap() {
    addMarkers(parkings, marker_car_pin);
  }

  function init_bikeMap() {
    addMarkers(bike_locations, marker_bike_pin);
  }
  
  function init_trainMap() {
    addMarkers([mainTrain], marker_train_pin); 
    getRoute(mainTrain, fairLocation, trainRoute, function(route) {
      trainRoute = route;
    });
  }
  
  function init_busMap() {
    addMarkers([mainBus], marker_bus_pin);
    addMarkers(stationsBus, marker_bus_pin);
  }

  function addMarkers(locations, pin, primary = false) {
    if(location) {
      locations.forEach(location => {
        var markerPopup = new mapboxgl.Popup({ offset: {
            'top': [0, 0], 
            'bottom': [0, -30], 
            'left': [15, -15],
            'right': [-15, -15]} })
          .setHTML(location['title']);

        var customMarker = document.createElement('div');
        customMarker.style.backgroundImage = 'url("' + pin + '")';
        customMarker.style.backgroundSize = 'contain';
        customMarker.style.backgroundPosition = 'center';
        customMarker.style.backgroundRepeat = 'no-repeat';
        customMarker.style.width = '30px';
        customMarker.style.height = '30px';
  
        var marker = new mapboxgl.Marker({ element: customMarker, offset: [0, -15] })
          .setLngLat([location['lon'], location['lat']])
          .setPopup(markerPopup)
          .addTo(map);

          if(!primary)
            allMarkers.push(marker);
          else
            marker.togglePopup();
      });
    }
  }

  function removeMarkers() {
    if (allMarkers.length > 0) {
      allMarkers.forEach(marker => {
        marker.remove();
      });

      if (map.getSource('route')) {
        map.removeLayer('route');
        map.removeSource('route');
      }
      
    }
  }

  function fitBounds() {
   
    // Create a 'LngLatBounds' with both corners at the first coordinate.
    const bounds = new mapboxgl.LngLatBounds(
      allMarkers[0].getLngLat(),
      allMarkers[0].getLngLat()
    );
    
    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    allMarkers.forEach(coord => {
      bounds.extend(coord.getLngLat());
    });
    
    const point = map.project([fairLocation['lon'], fairLocation['lat']]);
    map.fitBounds(bounds, {
      padding: 50,
    });

  }

  function getRoute(start, end, route, callback) {
    if(!route) {
      $.get( 'https://api.mapbox.com/directions/v5/mapbox/walking/' + start.lon + ',' + start.lat + ';' + end.lon + ',' + end.lat + '?steps=true&geometries=geojson&access_token=' + accessToken + '', function(data) {
        route = data.routes[0].geometry.coordinates;
        drawRoute(route);
        callback(route);
      });
    } else {
      drawRoute(route);
      callback(route);
    }
  }

  function drawRoute(route) {
    const geojson = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: route
      }
    };

    if (map.getSource('route')) {
      map.getSource('route').setData(geojson);
    } else {
      map.addLayer({
        id: 'route',
        type: 'line',
        source: {
          type: 'geojson',
          data: geojson
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#662878',
          'line-width': 5,
          'line-opacity': 0.75
        }
      });
    }
  }

  function addGmapLinks()  {
    // http://maps.google.com/?mode=walking&saddr=51.504303,-0.114187&daddr=51.502859,-0.107943
    // https://www.google.com/maps?q=760+West+Genesee+Street+Syracuse+NY+13204

    parkings.forEach(element => {
      element.title = element.title + '<br><a target="_blank" href="https://www.google.com/maps?q=' + element.lat + ',' + element.lon + '">' + gmapText + '</a>';
    });

    mainTrain.title = mainTrain.title + '<br><a target="_blank" href="http://maps.google.com?mode=walking&saddr=' + mainTrain.lat + ',' + mainTrain.lon + '&daddr=' + fairLocation.lat + ',' + fairLocation.lon + '">' + gmapText + '</a>';
    
    if(stationsBus.length > 0) {
      stationsBus.forEach(element => {
        element.title = element.title + '<br><a target="_blank" href="http://maps.google.com?mode=walking&saddr=' + element.lat + ',' + element.lon + '&daddr=' + fairLocation.lat + ',' + fairLocation.lon + '">' + gmapText + '</a>';
      });
    }

    if (bike_locations.length > 0) {
      bike_locations.forEach(element => {
        element.title = element.title + '<br><a target="_blank" href="http://maps.google.com?mode=walking&saddr=' + element.lat + ',' + element.lon + '&daddr=' + fairLocation.lat + ',' + fairLocation.lon + '">' + gmapText + '</a>';
      });
    }
  }
}


