var $wkb_posts = $(document).find('.wkb-posts');
if( $wkb_posts.length > 0 ) {
  $.each($wkb_posts, function( index, posts ) {
    var $posts = $(posts);
    var $slides = $('.owl-carousel', $posts);
    if( $slides.length > 0 ) {
      var owl = $slides.owlCarousel({
        items: 1,
        dots: true,
        dotsContainer: '#' + $posts.find('.owl-dots').prop('id'),
        nav: true,
        navContainer: '#' + $posts.find('.owl-nav').prop('id'),
        navText: ['<span class="icon icon-arrow-left"></span>', '<span class="icon icon-arrow-right"></span>'],
        loop: false,
        autoWidth: true,
        margin: 30,
      });

      $('.owl-dot', $posts).click(function () {
        owl.trigger('to.owl.carousel', [$(this).index(), 300]);
      });
    }
  });
}
