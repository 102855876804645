// import external dependencies
import 'jquery';
import 'owl.carousel';
import 'lightbox2';
import 'select2';
//import 'add-to-calendar-button';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

//import wk modules scripts
import './modules/banner';
import './modules/gallery';
import './modules/video';
//import './modules/google-maps';
import './modules/popup';
import './modules/posts-slider';
import './modules/events_filter';
import './modules/fair-map';
import './modules/mapbox';
import './modules/tickets';
import './modules/menu';
import './modules/booking';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
