const ics = require('ics');

var $wkb_events_filter = $(document).find('.wkb-events-filter form');

if ( $wkb_events_filter.length > 0 ) {
  var $filter_trigger = $wkb_events_filter.find('.filter-trigger');
  var $categories = $wkb_events_filter.find('.categories-container');
  var $close_filter = $wkb_events_filter.find('.close-filter');
  var ajaxurl = ajax.url;

  $filter_trigger.on('click', function() {
    $categories.addClass('active');
  });

  $close_filter.on('click', function() {
    $categories.removeClass('active');
  });

  $.each( $wkb_events_filter, function(index, val) {

    var $form = $(val);
    var $loader = $form.find('.loader');
    
    setTimeout(ajax, 200);

    $form.find('.filter input[type="checkbox"]').on('change', function() {
      ajax();
    });

    $form.find('.filter .day').on('click', function() {
      let $this = $(this);
      if (!$this.hasClass('active')) {
        $form.find('.filter .day').removeClass('active');
        $this.addClass('active');
        ajax();
      }
    });

    $form.on('click', '.calendar-trigger', function() {
      let single_data = $(this).data('ics');
      if(single_data) {
        generateIcs([single_data]);
      } else {
        generateIcs($form.find('.events').data('ics'));
      }
    });
  
    function generateIcs(data) {
      const { error, value } = ics.createEvents(data);
  
      if (error) {
        console.log('error', error);
        return;
      }
  
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(value));
      element.setAttribute('download', 'GR-events.ics');
  
      element.style.display = 'none';
      document.body.appendChild(element);
  
      element.click();
  
      document.body.removeChild(element);
    }

    function ajax() {
      if ( $form.length > 0 ) {
        $.ajax({
          url: ajaxurl,
          type: 'post',
          dataType: "json",
          data: {
            action: 'get_events_filter',
            event_date: $form.find('.day.active').data('date'),
            event_open: $form.find('.day.active').data('open'),
            event_close: $form.find('.day.active').data('close'),
            form: getFormData()
          },
          beforeSend: function () {
            $loader.removeClass('d-none');
          },
          complete: function() {
            $loader.addClass('d-none');
          },
          success: function (result) {
            $form.find('.events').html(result.events_html);
            $form.find('.events').data('ics', result.ics_data);
          },
          error: function(xhr, ajaxOptions, thrownError) {
            console.log(thrownError);
          }
        });
      }
    }

    function getFormData(){
      var unindexed_array = $form.serializeArray();
      var indexed_array = {};
  
      $.map(unindexed_array, function(n, i){
        if ( ! ( n['name'] in indexed_array ) ) {
          indexed_array[n['name']] = n['value'];
        } else {
          indexed_array[n['name']] = indexed_array[n['name']] + ',' + n['value'];
        }
      });
    
      return indexed_array;
    }
  });

}

